




















import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: { FontAwesomeComponent },
})
export default class NavigationBannerWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({
    required: false,
    default: null,
  })
  private readonly title!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly route!: string;

  @Prop({
    required: false,
    default: 'arrow-left',
  })
  private readonly icon!: string;

  created(): void {
    this.setDataConfig();
  }

  private onBtnClick(): void {
    let r = this.route;
    const matches = this.route.match(/(%[a-zA-Z-_]+%)/gs);
    if (matches) {
      matches.forEach((m) => {
        const prop = m.replaceAll('%', '')
          .trim();
        if (this.$route.params[prop]) {
          r = r.replaceAll(m, this.$route.params[prop]);
        }
      });
    }
    this.$router.push(r);
  }
}
